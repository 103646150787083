<template>
  <div class="seo-text-block module">
    <VaimoTruncatedText
      v-if="data.text"
      v-bind="data"
      class="module__text"
      markdown
    />
  </div>
</template>

<script>
import { computed, defineComponent } from '@nuxtjs/composition-api';

import { useScreenSize } from '~/diptyqueTheme/composable/useScreenSize';

export default defineComponent({
  name: 'SeoTextBlock',
  components: {
    VaimoTruncatedText: () => import('atoms/VaimoTruncatedText.vue')
  },
  props: {
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const { isDesktop } = useScreenSize();
    const linesQty = computed(() => (isDesktop.value ? 2 : 5));

    const data = computed(() => {
      return {
        text: props.sectionData?.text,
        truncate: 'by lines',
        lines: linesQty.value
      };
    });

    return {
      data
    };
  }
});
</script>

<style lang="scss" scoped>
.seo-text-block {
  padding: 0 var(--spacer-md);
  text-align: center;

  .module__text {
    font-size: var(--font-size--sm);
    font-weight: var(--font-weight--light);
    color: $gris_perle;
    line-height: 1.4;

    @include for-screen-m-and-l {
      font-size: var(--font-size--base);
    }

    ::v-deep {
      a {
        color: $gris_perle;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $gris_perle;
        font-family: var(--font-family--secondary);
        font-size: var(--font-size--base);
        font-weight: var(--font-weight--normal);
        line-height: 1.4;
        text-transform: uppercase;
        margin-bottom: var(--spacer-sm);
        -webkit-font-smoothing: initial;

        @include for-screen-m-and-l {
          font-size: var(--font-size--lg);
        }
      }
    }
  }

  @include for-screen-m-and-l {
    padding: 0 270px;
  }
}
</style>
